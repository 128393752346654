<style type="text/css">
  @import "~@/assets/css/common.css";
</style>
<style type="text/css" scoped>
  /deep/.el-select.blueBorder .el-input__inner {
    border-color: rgb(23, 118, 210);
    height: 30px
  }

  /deep/ .el-form-item__content {
    line-height: inherit;
  }

  /deep/.el-form-item {
    margin-bottom: 0;
  }

  /deep/.el-input__inner {
    height: 30px;
  }

  /deep/.el-input__icon {
    line-height: inherit;
  }

  /* 特殊名单 */
</style>
<script>
  import Layout from "@/views/layouts/main";
  import CheckHeader from "@/components/check-header";
  import {
    getTsmdk,
    editTsmdk,
    deleteTsmdk,
    addTsmdk
  } from "@/api/admin/exam/createExamku.js"
  import {
    // getExamEasy,
    getNowExam
  } from "@/api/admin/exam/examRecord.js"
  import ChangeExam from '@/components/change-exam.vue'
  // import {
  //   checkFile,
  // } from "@/api/index.js"
  /**
   * 特殊名单
   */
  export default {
    components: {
      Layout,
      CheckHeader,
      ChangeExam


    },
    data() {
      return {
        title: "考试特殊名单库/",
        title2: "2021年二级造价工程师职业资格增报专业考试特殊名单库",
        subTitle: "切换报名",
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "特殊名单库",
            active: true
          }
        ],
        tableList: [],
        exam: [],
        module: "TSMDK",
        fileId: "",
        excelFields: [],
        years: [],
        addForm: {},
        showmodal: false,
        ksbmbh: "",
        ksnf: "",
        ksmc: "",
        imTitle: "考试特殊名单库导入",
        exTitle: "考试特殊名单库导出",
        select: {},
        pageData: {
          pageNum: 1,
          pageSize: 20,
          total: 0
        },
        uploadLimit: {}
      };
    },
    methods: {
      getList() {
        getTsmdk({
          ...this.pageData,
          ksbmbh: this.ksbmbh
        }).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.pageData.total = res.total
          }
        })
      },
      searchClick() {
        this.pageData.pageNum = 1
        this.getlistByParam()
      },
      handleSizeChange(val) {
        this.pageData.pageSize = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val
        this.getList();
      },
      getYear() { //获取年份，当前年前后几年
        var y = new Date().getFullYear();
        for (var i = 0; i <= 5; i++) {
          if (i < 5) {
            this.years.unshift({
              value: (y - i),
              label: (y - i)
            })
          } else {
            for (var x = 1; x < 3; x++) {
              this.years.push({
                value: (y + x),
                label: (y + x)
              })
            }
          }
        }
      },
      // 条件查询
      getlistByParam() {
        this.title2 = this.ksmc
        this.select.ksnf = this.ksnf
        this.select.ksbmbh = this.ksbmbh
        getTsmdk({
          ...this.pageData,
          ...this.select
        }).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.pageData.total = res.total;
          }
        })
      },
      //上传成功回调
      success() {
        this.getList()
      },
      // 上传
      uploadFile() {
        var _this = this;
        _this.$importModal().show({
          title: this.imTitle,
          module: this.module,
          ksbmbh: this.ksbmbh,
          success: this.success
        })
      },
      addItem() {
        this.addForm = {
          sfxgbm: true,
          sfjf: true,
          sftf: true,
          sfbbgw: true
        }
        this.showmodal = true
        this.addForm.ksbmbh = this.ksbmbh
        this.addForm.ksmc = this.ksmc

      },

      deleteItem(obj) {
        this.$confirm(`此操作将永久删除该【${obj.xm}】的数据, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteTsmdk(obj.sid).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '数据删除成功!'
              });
            }
            this.getList()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      //获取切换的考试
      getLastExam(params) {
        getNowExam().then(res => {
          if (res.status) {
            if (params == '') {
              this.ksbmbh = res.data.ksbmbh
              this.ksmc = res.data.ksmc
            } else {
              this.ksbmbh = params
            }
            this.getList({
              ksbmbh: this.ksbmbh
            })
          }
        })
      },
      addTsmd() {
        let formDate = JSON.parse(JSON.stringify(this.addForm))
        if (formDate.sfzjh && formDate.yxsj) {
          addTsmdk(formDate).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: res.message
              });
              this.showmodal = false
              this.getList()
            }
          })
        } else {
          this.$message({
            title: "提示",
            type: 'warning',
            message: "身份证件号和有效时间必填！"
          });
        }

      },
      // 改变启用状态
      changeZt(name, val, sid) {
        let f = {
          sid: sid,
        }
        f[name] = val
        editTsmdk(f).then(res => {
          if (res.status) {
            this.$message({
              type: 'success',
              message: '数据修改成功'
            });
          }
        })
      },
    },
    mounted() {

      this.ksnf = new Date().getFullYear();
      this.getYear()
      this.ksbmbh = this.$route.query.ksbmbh ? this.$route.query.ksbmbh : ''
      this.ksmc = this.$route.query.ksmc ? this.$route.query.ksmc : ''
      this.getLastExam(this.ksbmbh)
    }
  };
</script>

<template>
  <Layout>
    <CheckHeader :title="title" :title2="ksmc" :subTitle="subTitle" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList" style="flex: 1;">
                <!--  <el-select class=" mr-2 blueBorder" clearable style="width:10%;" placeholder="请选择年份" v-model="ksnf"
                  size="small">
                  <el-option v-for="(item, i) in years" :label="item.label" :value="item.value" :key="i">
                  </el-option>
                </el-select>
                <el-select class=" mr-2 blueBorder" clearable style="width: 15%;" placeholder="请选择考试"
                  v-model="select.ksbmbh" size="small" @change="getksmc">
                  <el-option :value="item.ksbmbh" v-for="(item,index) in exam" :key="index" :label="item.ksmc">
                    {{item.ksmc}}
                  </el-option>
                </el-select> -->
                <input placeholder="输入姓名查询" v-model="select.xm" class="h30 form-control border-blue mr-2 w-15"
                  maxlength="50" />
                <button type="button" class="btn btn-info h30 flexList mr-2" @click="searchClick"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</button>
                <b-button @click="addItem" variant="outline-info" class="flexList mr-2 condition"><i
                    class="iconfont icon-plus-circle1 mr-2 font-size-20"></i>添加</b-button>
                <el-button size="small" type="primary" plain class="flexList mr-2 h30 " @click="uploadFile"><i
                    class="iconfont icon-riLine-upload-2-line mr-2"></i>导入特殊名单库</el-button>
              </div>
              <div class="d-flex">
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title:exTitle, type: 'xlsx', module:module,condition:select})"><i
                    class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title: exTitle,type: 'dbf', module:module,condition:select})"><i
                    class="iconfont icon-data mr-2"></i>dbf</div>
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table light-table table-hover table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 4%;"> 序号</th>
                    <th style="width: 10%;">身份证号</th>
                    <th style="width:8%">姓名</th>
                    <th>是否允许修改报名信息 </th>
                    <th>是否允许缴费</th>
                    <th>是否允许退费</th>
                    <th>是否允许重新补报职位</th>
                    <th style="width: 10%;">有效时间</th>
                    <th style="width: 5%;" class="text-center">操作 </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj,index) in tableList" :key="index">
                    <td> {{index+1+(pageData.pageNum-1)*pageData.pageSize}}</td>
                    <td>{{obj.sfzjh}} </td>
                    <td>
                      <b-button variant="link" class="font-blue h30">{{obj.xm}}</b-button>
                    </td>
                    <td>
                      <b-form-checkbox v-model="obj.sfxgbm" switch class=" switch-check"
                        @change="changeZt('sfxgbm',obj.sfxgbm,obj.sid)">
                      </b-form-checkbox>
                    </td>
                    <td>
                      <b-form-checkbox v-model="obj.sfjf" switch class=" switch-check"
                        @change="changeZt('sfjf',obj.sfjf,obj.sid )">
                      </b-form-checkbox>
                    </td>
                    <td>
                      <b-form-checkbox v-model="obj.sftf" switch class=" switch-check"
                        @change="changeZt('sftf',obj.sftf,obj.sid)">
                      </b-form-checkbox>
                    </td>
                    <td>
                      <b-form-checkbox v-model="obj.sfbbgw" switch class=" switch-check"
                        @change="changeZt('sfbbgw',obj.sfbbgw,obj.sid)">
                      </b-form-checkbox>
                    </td>
                    <td>{{obj.yxsj}}</td>
                    <td class="tab-icon"> <i class="iconfont icon-riLine-delete-bin-line" @click="deleteItem(obj)"></i>
                    </td>
                  </tr>


                </tbody>
              </table>
            </div>
            <div class="float-right d-flex ">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>

          </div>
        </div>
      </div>


    </div>

    <!-- 弹窗开始 -->
    <ChangeExam></ChangeExam>
    <b-modal id="addClass" v-model="showmodal" centered title="添加考试特殊名单" title-class="font-18" hide-footer>
      <div>
        <el-form ref="addForm" :model="addForm">
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label class="w-25">考试编号：</label>
              <div class=" col-sm-10 p-0 flexList">
                <input type="text" name="" id="" value="" disabled v-model="addForm.ksbmbh"
                  class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label class="w-25">考试名称：</label>
              <div class=" col-sm-10 p-0 flexList">
                <input type="text" name="" id="" value="" disabled v-model="addForm.ksmc"
                  class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label class="w-25">身份证号：</label>
              <div class=" col-sm-10 p-0 flexList">
                <input type="text" name="" id="" value="" v-model="addForm.sfzjh" class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label class="w-25">姓名：</label>
              <div class="col-sm-10  p-0">
                <input type="text" name="" id="" value="" placeholder="姓名" v-model="addForm.xm"
                  class="form-control w-100 h30 " />
              </div>

            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label class="w-25">允许修改报名信息：</label>
              <div class="col-sm-10 p-0 flexList">
                <div class="form-check mr-2"><input type="radio" name="sfxgbm" id="sfxgbm1" v-model="addForm.sfxgbm"
                    value=true class="form-check-input"><label for="sfxgbm1" class="form-check-label">是
                  </label></div>
                <div class="form-check mr-2"><input type="radio" name="sfxgbm" id="sfxgbm2" v-model="addForm.sfxgbm"
                    value=false class="form-check-input"><label for="sfxgbm2" class="form-check-label">否</label>
                </div>

              </div>
            </div>
          </el-form-item>

          <el-form-item>
            <div class="flexList check-distri mb-3"><label class="w-25">允许缴费：</label>
              <div class="col-sm-10 p-0 flexList">
                <div class="form-check mr-2"><input type="radio" name="sfjf" id="sfjf1" v-model="addForm.sfjf"
                    value=true class="form-check-input"><label for="sfjf1" class="form-check-label">是
                  </label></div>
                <div class="form-check mr-2"><input type="radio" name="sfjf" id="sfjf2" v-model="addForm.sfjf"
                    value=false class="form-check-input"><label for="sfjf2" class="form-check-label">否</label>
                </div>

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label class="w-25">允许退费：</label>
              <div class="col-sm-10 p-0 flexList">
                <div class="form-check mr-2"><input type="radio" name="sftf" id="sftf1" v-model="addForm.sftf"
                    value=true class="form-check-input"><label for="sftf1" class="form-check-label">是
                  </label></div>
                <div class="form-check mr-2"><input type="radio" name="sftf" id="sftf2" v-model="addForm.sftf"
                    value=false class="form-check-input"><label for="sftf2" class="form-check-label">否</label>
                </div>

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label class="w-25">允许重新补报职位：</label>
              <div class="col-sm-10 p-0 flexList">
                <div class="form-check mr-2"><input type="radio" name="sfbbgw" id="sfbbgw1" v-model="addForm.sfbbgw"
                    value=true class="form-check-input"><label for="sfbbgw1" class="form-check-label">是
                  </label></div>
                <div class="form-check mr-2"><input type="radio" name="sfbbgw" id="sfbbgw2" v-model="addForm.sfbbgw"
                    value=false class="form-check-input"><label for="sfbbgw2" class="form-check-label">否</label>
                </div>

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>有效时间：</label>
              <div class="col-sm-10 p-0 flexList">
                <el-date-picker v-model="addForm.yxsj" @change="addForm.yxsj=formatDateDetails(new Date($event))"
                  class="h30 w-100" type="datetime" placeholder="选择日期时间" default-time="12:00:00"> </el-date-picker>

              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class=" mt-3  text-center"><button type="button" class="btn btn-info h30 w-md mr-3"
          @click="addTsmd">确定</button>
        <button type="button" class="btn btn-secondary h30  w-md" @click="showmodal = false">取消</button>
      </div>



    </b-modal>
    <!-- 弹窗结束 -->
  </Layout>
</template>
